import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, delay, of } from 'rxjs';
import * as uuid from 'uuid';
import { Toast } from '../toast';



@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private toasts$: BehaviorSubject<Toast[] | null> = new BehaviorSubject<Toast[] | null>(null)
  private defaultToastLife = 10000;
  constructor() { }

  getToasts(): Observable<Toast[] | null>{
    return this.toasts$.asObservable();
  }

  pushToast(toast: Toast): Toast{

    if(toast.id){
      this.popToast(toast.id)
    }else {
      let id = uuid.v4();
      toast.id = id;
    }

    let existingToasts: Toast[] | null = this.toasts$.getValue();
    if(existingToasts){
      existingToasts.push(toast);
    }else {
      existingToasts = [toast];
    }
    this.toasts$.next(existingToasts);

    if(!toast.sticky){
      of(toast)
      .pipe(delay(toast.customDelay || this.defaultToastLife))
      .subscribe((x) => {
        this.popToast(x.id)
      })
    }
    return toast;
  }

  popToast(toastId: string | undefined) {
    let existingToasts: Toast[] | null = this.toasts$.getValue();
    if(existingToasts){
      let filteredToast = existingToasts.filter((t: Toast) => t.id !== toastId);
      this.toasts$.next(filteredToast);
    }
  }
}

<div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
    <div class="flex flex-1 justify-end px-4">
        <div class="ml-4 flex items-center md:ml-6">
            <div class="relative inline-block text-left">
                <div>
                    <button type="button" (click)="toggleDropdown()"
                        class="inline-flex w-full justify-center rounded-md  bg-white px-4 py-2 text-sm font-medium text-gray-700  focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        id="menu-button" aria-expanded="true" aria-haspopup="true">
                        <span>{{authenticator.user.attributes?.email}}</span>
                        <svg *ngIf="!isDropdownVisible" class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                        <svg *ngIf="isDropdownVisible" class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>
                </div>
                <div *ngIf="isDropdownVisible"
                    class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                        <button type="button" (click)="signOut()"
                            class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1"
                            id="menu-item-3">Sign out</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(Auth.currentSession()).pipe(
      mergeMap((authSession) => {
        let token = authSession.getAccessToken().getJwtToken();
        if(request.url.includes("s3.us-east-1.amazonaws.com")){
          console.log('auth interceptor skip ');
          return next.handle(request);
        }else {
          const authReq = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`)
          })
          return next.handle(authReq)
        }
        
      })
    )
  }
}


<app-sidebar></app-sidebar>
<div class="flex flex-1 flex-col md:pl-36  overflow-x-hidden	">
    <app-navbar></app-navbar>
    <main>
        <div class="p-2">
            <div class="mx-auto ">
                <div class="py-4">
                    <router-outlet *ngIf="true"></router-outlet>
                </div>
            </div>
        </div>
    </main>
</div>
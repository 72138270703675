import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { LayoutModule } from './layout/layout.module';
import { NoopAnimationPlayer } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlowbiteModule } from 'flowbite-angular';
import { StoreModule } from '@ngrx/store';
import { layoutReducer } from './shared/store/layout.reducer';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { NotificationModule } from './notification/notification.module';
import { ToasterModule } from './toaster/toaster.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    LayoutModule,
    FlowbiteModule,
    StoreModule.forRoot({ layout: layoutReducer }, {}),
    ToasterModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

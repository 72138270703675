//DEV

export const environment = {
    production: true,
    apiURL: 'https://api.dev.mandel.tunclab.net',
    // apiURL: 'http://localhost:3000',
    assetsBucketName: 'app.dev.mandel.tunclab.net',
    assetsEndpoint: 'https://app.dev.mandel.tunclab.net',
    awsRegion: 'us-east-1',
    userPoolId: 'us-east-1_zx6fJFsdu',
    userPoolClient: '5jg43946sm4g9fgbolledc0gt2'
};

//PROD

// export const environment = {
//     production: true,
//     apiURL: 'https://api.mandel.tunclab.net',
//     // apiURL: 'http://localhost:3000',
//     assetsBucketName: 'app.mandel.tunclab.net',
//     assetsEndpoint: 'https://app.mandel.tunclab.net',
//     awsRegion: 'us-east-1',
//     userPoolId: 'us-east-1_ZxvgJe0rE',
//     userPoolClient: '67oqfouclqefe9odsi9qm8jlao'
// };

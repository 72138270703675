import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  routes = [
    {
      name: 'Orders',
      href: '/orders'
    },
    {
      name: 'Inventory',
      href: '/inventory',
      children: [
        {
          name: 'Products',
          href: '/inventory/products'
        },
        
        {
          name: 'Price Groups',
          href: '/inventory/price-groups'
        },
        {
          name: 'Categories',
          href: '/inventory/categories'
        },
        {
          name: "Brands",
          href: '/inventory/brands'
        },
        {
          name: 'Size',
          href: '/inventory/size'
        },
        {
          name: 'Bulk Update',
          href: '/inventory/update-product-prices'
        }
        
      ]
    },
    {
      name: 'Deals',
      href: '/deals'
    },
    {
      name: 'Customer',
      href: '/customer'
    },
    {
      name: 'Returns',
      href: '/product-returns'
    },
    {
      name: 'Store',
      href: '/store'
    },
    {
      name: 'News',
      href: '/news'
    },
    {
      name: 'Staff',
      href: '/staff'
    }
  ]
}

<div>
  <amplify-authenticator [hideSignUp]="true">
    <ng-template amplifySlot="header">
      <div class="flex items-center justify-center">
        <div class="w-40">
          <img class="amplify-image" alt="Amplify logo" src="../assets/Mandel_logo.png" />
        </div>
      </div>
    </ng-template>

    <ng-template amplifySlot="sign-in-header">
      <div class="flex items-center justify-center pt-5">
        <span class="font-bold text-3xl	">
          Sign in to your account
        </span>
      </div>
    </ng-template>

    <ng-template amplifySlot="footer">
      <div style="padding: var(--amplify-space-large); text-align: center">
        <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
          © All Rights Reserved
        </p>
      </div>
    </ng-template>

    <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
      <div>
        <!-- <router-outlet></router-outlet> -->
        <!-- <button (click)="signOut()">Sign Out</button> -->
        <app-dashboard-layout>

        </app-dashboard-layout>
        <app-toaster></app-toaster>
        
      </div>
    </ng-template>

  </amplify-authenticator>
</div>
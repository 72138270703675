import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent {

  ieExpanded: boolean = true;

  $layoutStore: Observable<any>;

  constructor(private store: Store<{ layout: any }>) {
    this.$layoutStore = store.select('layout');

    this.$layoutStore.subscribe((results) => {
      console.log('layout results', results);
      this.ieExpanded = results.isExpanded;
    })
  }
}

import { Component } from '@angular/core';
import { Observable, Subject, takeUntil, of, delay } from 'rxjs';
import { ToasterService } from './service/toaster.service';
import { Toast, ToastType } from './toast';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css']
})
export class ToasterComponent {
  unsubscribe$: Subject<boolean> = new Subject();
  toasts: Toast[] | null = null;
  ToastType = ToastType;
  constructor(private toasterService: ToasterService){

  }

  ngOnInit(){
    this.toasterService.getToasts()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(toasts => {
      this.toasts = toasts;
    })
  }

  ngOnDestroy(){
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  removeToast(toast: Toast){
    this.toasterService.popToast(toast.id)
  }
}

export enum ToastType {
    PROGRESS,INFO,WARN,ERROR,SUCCSS
}

export interface Toast {
    type: ToastType,
    message: string;
    sticky?: boolean;
    customDelay?: number;
    id?: string;
}

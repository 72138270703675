import { Component } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  public isDropdownVisible = false;

  constructor(public authenticator: AuthenticatorService) { 
    
  }

  public signOut() {
    this.authenticator.signOut();
    // console.log(this.authenticator.user)
  }

  public toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible
  }
}

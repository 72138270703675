import { createReducer, on } from '@ngrx/store';
import { expand, shrink } from './layout.actions';



export const initialState = {
    isExpanded: false
}

export const layoutReducer = createReducer(
    initialState,
    on(expand, (state) => ({ ...state, isExpanded: true })),
    on(shrink, (state) => ({ ...state, isExpanded: false }))
)
<div class="hidden md:fixed md:inset-y-0 md:flex md:w-36 md:flex-col">
    <div class="flex flex-grow flex-col overflow-y-auto bg-blueberry-700 ">
        <div class="flex flex-shrink-0 items-center">
            <img class="h-16 w-auto" src="../../../../assets/mandel_logo_long.png" alt="Your Company" />
        </div>
        <div class="mt-5 flex flex-1 flex-col">
            <nav class="flex-1 space-y-1 px-2 pb-4">
                <div *ngFor="let route of routes">
                    <a [routerLink]="route.href"
                        class="group flex items-center cursor-pointer px-2 py-2 text-sm font-medium rounded-md text-blueberry-100 hover:bg-blueberry-600">
                        {{route.name}}


                    </a>

                    <nav class="flex-1 space-y-1 px-2 ml-3" *ngFor="let child of route.children">
                        <a [routerLink]="child.href"
                            class="group flex items-center cursor-pointer px-2 py-2 text-sm font-medium rounded-md text-blueberry-100 hover:bg-blueberry-600">
                            {{child.name}}
                        </a>
                    </nav>
                </div>


                <!-- <div *ngIf="route.children">

                    <nav class="flex-1 space-y-1 px-2 ml-3" *ngFor="let child of route.children">
                        <a [routerLink]="child.href"
                            class="group flex items-center cursor-pointer px-2 py-2 text-sm font-medium rounded-md text-blueberry-100 hover:bg-blueberry-600">
                            {{child.name}}
                        </a>
                    </nav>
                </div> -->

            </nav>
        </div>
    </div>
</div>